<template lang="html">
  <section class="entries-page">
    <div class="container">
      <div class="box-filters">
        <p class="title-line">Últimas vacantes</p>
      </div>
      <div class="container-offers grid-4">
        <div class="grid" v-for="(offer, key) in Object.entries(store.offers).slice(0,4)" :key="key">
          <div class="offers-data">
            <p class="title">{{ offer[1].name }}</p>
            <p class="description">{{ offer[1].header }}</p>
          </div>
          <button class="btn-blue-border" @click="gotoOffer(offer[1].slug)">Más información</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { contentStore } from '@/stores/contents'
const store = contentStore();
export default {
    name: 'entries-page',
    props: [],
    components: {
    },
    setup() {
        return {
            store
        }
    },
    mounted() {
        this.categorySelected = store.entries.lastFilters.filter;
        this.textSearch = store.entries.lastFilters.text,
        store.loadOffers()

    },
    data() {
        return {

        }
    },
    methods: {
      gotoOffer: function (value) {
       this.$router.push({
        name: 'offersPage', 
        query: { 'slug': value },
      })
        
      },

    },
    computed: {

    },
    watch: {

    }

}
</script>

<style scoped lang="scss">
.entries-page {
  margin-top: 50px;
}
</style>
