<template lang="html">

  <section class="banner-links d-flex  aife">
    <img src="/img/bg-home/campus4.jpg" alt="Campus">
    <div class="aifs column-center container container-main-link">
      <h1 class="title-line -white" >DESARROLLO DE PERSONAS UFV</h1>
      <h2 class="title -white" >Talento</h2>
    </div>
  </section>

</template>

<script lang="js">
import { contentStore } from '@/stores/contents'

export default {
  name: 'banner-links',
}
</script>