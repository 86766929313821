<template>
  <div class="home">
    <bannerLinks titleBanner="desarrollo-personas" >
    </bannerLinks>
    <sliderHor :dataSlider="store.adds.items"></sliderHor>
    <bannerEntry></bannerEntry>
  </div>

</template>

<script>
  // @ is an alias to /src
  import bannerLinks from '@/components/banner-links.vue'
  import sliderHor from '@/components/slider-horizontal.vue'
  import bannerEntry from '@/views/bannerEntry.vue'

  import { contentStore } from '@/stores/contents'
  const store = contentStore();
  export default {
    name: 'home',
    props: [],
    components: {
      bannerLinks,
      sliderHor,
      bannerEntry
    },
    setup() {
      return {
        store
      }
    },
    data() {
      return {
        page: 1,
      }
    },
    mounted() {
      store.loadDirectAccess();
      store.loadAdds()
      store.loadSpecialEntries()
      
    },

  };
</script>
<style>
  ul,
  li {
    list-style: none;
  }
</style>