<template lang="html">

  <section class="slider-horizontal">
    <agile :options="myOptions" ref="carousel" v-if="Object.keys(dataSlider).length != 0">
      <template v-for="(banner, key ) in dataSlider" :key="key">
        <div class="slide w-100">
          <div class="d-flex jcsb container-slide">
            <div class="div-text container">
              <p class="title-line  -white uppercase">
                {{banner.name}}
              </p>
              <p class="description">
                {{banner.header}}
              </p>
              <a v-if="banner.url" :href="banner.url" class="btn-white-border" target="_blank" title="Más información">Más información</a>
            </div>
            <div class="div-image" v-if="banner.image.url">
              <img :src="banner.image.url" :alt="banner.name">
            </div>
          </div>
        </div>
        
      </template>

    </agile>
  </section>

</template>

<script lang="js">
  import {
    VueAgile
  } from "vue-agile";
  export default {
    name: 'slider-horizontal',
    components: {
      agile: VueAgile
    },
    props: ['dataSlider'],
    data: () => ({
      myOptions: {
        responsive: [
          {
            breakpoint:  1400,
            settings: {
              fade: true,
              navButtons: false,
              slidesToShow: 1,
              dots: false,
            }

          }, {
            breakpoint: 0,
            settings: {
              dots: true,
              slidesToShow: 1,
              navButtons: false,

            },
          },
        ],
      },
    }),
  }


</script>

<style scoped lang="scss">
  .slider-horizontal {}
</style>